<template>
  <div id="order-view-action-send-invoice">
    <b-button
      id="order-view-action-send-invoice"
      v-ripple.400
      variant="success"
      class="mb-75"
      block
      :disabled="disabled || quoteTouched || !recallInvoiceValid"
      @click.prevent="sendInvoiceEdition = true"
    >
      <feather-icon icon="SendIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ btnText || $t('action.send_invoice') }} </span>
    </b-button>
    <div v-if="!recallInvoiceValid" class="mb-75">
      <small class="text-danger">{{ $t('order.no_price_increase') }}</small>
    </div>

    <app-sidebar-form
      form-name="order-view-action-send-invoice"
      :edition="sendInvoiceEdition"
      edition-mode-title="edit"
      :disabled="filesLoading"
      :title="btnText || $t('action.save_and_send_invoice')"
      :entity="quoteSelected.quote"
      :submit-btn-text="$t('action.save_and_send_invoice')"
      submit-btn-icon="SendIcon"
      submit-btn-variant="success"
      no-pristine
      @update:edition="sendInvoiceEdition = $event"
      @update:entity="sendInvoice($event)"
    >
      <template #fields="data">
        <b-row>
          <div v-if="data.item.state === 'awaiting_for_quotation'" class="mb-1">
            <b-col cols="12">
              <span class="font-weight-bold text-nowrap">{{ `${$tc('payment.term.title', 2)} : ` }} </span>
              <span class="text-nowrap">{{ data.item.paymentTerm | enumTranslate('quote_payment_term') }} </span>
            </b-col>
            <b-col v-if="data.item.concretePaymentDate" cols="12">
              <span class="font-weight-bold text-nowrap">{{ `${$t('payment.term.specific_terms')} : ` }} </span>
              <span class="text-nowrap">
                {{
                  `${$t('payment.date.concrete_payment_at')} ${new Date(data.item.concretePaymentDate).toLocaleDateString($i18n.locale, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    weekday: 'short',
                  })} `
                }}
              </span>
            </b-col>
          </div>
          <b-col cols="12">
            <!-- message -->
            <validation-provider #default="{ errors }" :name="$t('common.message')" rules="" vid="order-view-action-save-and-send-message-provider">
              <b-form-group :label="$t('common.message')" label-for="order-view-action-save-and-send-message">
                <b-form-textarea
                  id="order-view-action-save-and-send-message"
                  v-model="data.item.message"
                  :placeholder="$t('common.message')"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- servicesDelivered -->
          <b-col v-if="data.item.state === 'awaiting_for_quotation'" cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('order.services_delivered.title')"
              rules=""
              vid="order-view-action-save-and-send-services-delivered-provider"
            >
              <b-form-group :label="$t('order.services_delivered.title')" label-for="order-view-action-save-and-send-services-delivered">
                <b-form-checkbox id="order-view-action-save-and-send-services-delivered" v-model="data.item.servicesDelivered">
                  {{ $t('order.services_delivered.text') }}
                </b-form-checkbox>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- files -->
          <b-col cols="12">
            <AppAxiosFormFile @updateId="filesIds = $event" @upload-in-progress="filesLoading = $event" />
          </b-col>
          <b-col cols="12">
            <b-form-group
              label-for="externalInvoiceRequest"
              class="mt-1"
            >
              <b-form-checkbox v-model="externalInvoiceRequest">
                {{ $t('external_invoice.send_checkbox') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col v-if="externalInvoiceRequest === true" cols="12">
            <b-form-group :label="$t('external_invoice.number')" label-for="referenceExternalInvoice">
              <validation-provider
                #default="{ errors }"
                :name="$t('external_invoice.number')"
                :rules="externalInvoiceRequest === true ? 'required' : ''"
                vid="order-view-action-send-invoice-number-provider"
              >
                <b-form-input
                  id="referenceExternalInvoice"
                  v-model="referenceExternalInvoice"
                  :placeholder="$t('external_invoice.number')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="externalInvoiceRequest === true" cols="12">
            <b-form-group :label="$t('external_invoice.date')" label-for="externalInvoiceDate">
              <validation-provider
                #default="{ errors }"
                :name="$t('common.date') | capitalize"
                :rules="externalInvoiceRequest === true ? 'required' : ''"
                class="validation-required"
              >
                <b-form-datepicker
                  v-model="externalInvoiceDate"
                  :state="errors.length > 0 ? false : null"
                  class="mb-1"
                  :placeholder="$t('external_invoice.date')"
                  :locale="$i18n.locale"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="externalInvoiceRequest === true" cols="12">
            <b-form-group :label="$t('external_invoice.total_excl_taxes')" label-for="totalInvoiceExcludingTaxes">
              <validation-provider
                #default="{ errors }"
                :name="$t('external_invoice.total_excl_taxes')"
                :rules="externalInvoiceRequest === true ? 'required' : ''"
                vid="order-view-action-send-invoice-total-excl-taxes-provider"
              >
                <b-input-group :append="currency">
                  <b-form-input
                    id="external-invoice-total-excl-taxes"
                    v-model="totalInvoiceExcludingTaxes"
                    :placeholder="$tc('external_invoice.total_excl_taxes', 1)"
                    :state="errors[0] && false"
                    :formatter="numberFormat"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="externalInvoiceRequest === true" cols="12">
            <b-form-group :label="$t('external_invoice.total_incl_taxes')" label-for="totalInvoiceIncludingTaxes">
              <validation-provider
                #default="{ errors }"
                :name="$t('external_invoice.total_incl_taxes')"
                :rules="externalInvoiceRequest === true ? 'required' : ''"
                vid="order-view-action-send-invoice-total-incl-taxes-provider"
              >
                <b-input-group :append="currency">
                  <b-form-input
                    id="external-invoice-total-incl-taxes"
                    v-model="totalInvoiceIncludingTaxes"
                    :placeholder="$tc('external_invoice.total_incl_taxes', 1)"
                    :state="errors[0] && false"
                    :formatter="numberFormat"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppAxiosFormFile from '@/components/AppAxiosFormFile.vue'

import { postQuoteSendInvoiceRequest } from '@/request/globalApi/requests/quoteRequests'
import getCurrency from '@/helpers/getCurrency'

export default {
  name: 'OrderViewActionSendInvoice',

  components: {
    AppSidebarForm,
    AppAxiosFormFile,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    btnText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      sendInvoiceEdition: false,
      referenceExternalInvoice: null,
      externalInvoiceDate: null,
      totalInvoiceExcludingTaxes: null,
      totalInvoiceIncludingTaxes: null,
      filesLoading: false,
      externalInvoiceRequest: false,
      currency: null,
      filesIds: [],
    }
  },

  mounted() {
    this.currency = getCurrency()
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTouched']),
    ...mapGetters('quote', ['quoteTitle']),
    ...mapFields('quote', ['quoteLoading', 'quoteEditable']),

    recallInvoiceValid() {
      if (this.quoteSelected?.quote?.state !== 'delivered') return true
      if (this.quoteSelected?.quote?.state === 'delivered') {
        return this.quoteSelected?.quote?.priceIncludingTaxes <= this.quoteSelected?.quote?.totalPriceIncludingTaxesAtAcceptation
      }
      return true
    },
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    sendInvoice(quote) {
      this.quoteLoading = true
      postQuoteSendInvoiceRequest(
        {
          id: quote.id,
          message: quote.message,
          externalInvoiceRequest: this.externalInvoiceRequest,
          referenceExternalInvoice: this.referenceExternalInvoice,
          date: this.externalInvoiceDate,
          totalInvoiceExcludingTaxes: this.totalInvoiceExcludingTaxes,
          totalInvoiceIncludingTaxes: this.totalInvoiceIncludingTaxes,
          showInvoiceUrl: window.location.href,
          filesIds: this.filesIds,
        },
        this.$store.getters['quote/quoteTitleAndRef'],
        this.$t('request.action.send'),
      )
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteEditable = false
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
    numberFormat: value => value.replace(/,/g, '.'),
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
